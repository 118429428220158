export default ['$scope', '$async', 'ywUtil', 'Catalog', 'localStorageService',
  function controller ($scope, $async, ywUtil, Catalog, localStorageService) {
    $scope.filterItems = [];
    $scope.tableHead = [];
    $scope.initActionItem = initActionItem;

    const init = $async(async () => {
      // rendering options
      $scope.toolbar = ywUtil.getToolbarSettings();
      $scope.tableHead = ywUtil.getTableHeader();
      $scope.searchBar = ywUtil.getSearchbarSettings();
      ywUtil.initSearchBarOptions(
        $scope,
        [
          { btnName: '目錄名稱', placeholder: '請輸入目錄名稱', params: 'name' }
        ]
      );

      $scope.filterItems = [
        {
          name: 'isVisible',
          label: '是否顯示',
          options: { true: '顯示', false: '隱藏' },
          changed: $scope.mainAction.setClearFilterTrue,
          type: 'noOwnAttrSelect'
        }
      ];

      $scope.queryPage = 'catalog'; // for GA

      const catalogId = JSON.parse(localStorageService.get('catalog'));
      localStorageService.remove('catalog');

      $scope.mainAction.initResource(Catalog, { catalogId }).then(() => {
        if (catalogId) $scope.changePageState('edit', _.head($scope.items));
      });
    });

    init();

    function initActionItem () {
      $scope.currentActionItem = {
        isVisible: true,
        isVenderListSearchVisible: true,
        isTa3Visible: true,
        subcatalogs: [],
        sort: 0,
        displayBelowBannerInfoIsActivate: true
      };
    }

    $scope.cellActions = {
      edit: (item) => {
        localStorageService.set('catalog', JSON.stringify(item._id));
        window.open('catalog', '_blank');
      }
    };
  }];
