export default ['$scope', 'Catalog', 'subcatalogEditorDialog', 'ServiceClassification',
  function controller ($scope, Catalog, subcatalogEditorDialog, ServiceClassification) {
    const self = this;

    self.avatarFiles = {};
    self.uploaded = $scope.imageUploaded;
    self.singleFileUploaded = $scope.singleFileUploaded;
    $scope.inputItems = [];
    $scope.transform = transform;
    $scope.mainAction._validator = validator;

    function validator () {
      const item = $scope.currentActionItem;
      console.log('validator:', item);

      if (!item.name) return $scope.alert('請輸入目錄標題名稱');
      if (item.name.length > 6) return $scope.alert('目錄標題名稱限6個字');
      if (item.link && !/^http/.test(item.link)) return $scope.alert('標題連結網址需為 http:// or https:// 開頭');
      if (item.displayBelowBannerInfoIsActivate) {
        if (!item.displayBelowBannerInfo.image) return $scope.alert('請上傳電腦版目錄代表圖');
        if (!item.displayBelowBannerInfo.mobileImage) return $scope.alert('請上傳手機版目錄代表圖');
        if (!item.displayBelowBannerInfo.description) return $scope.alert('請輸入目錄描述');
        if (item.displayBelowBannerInfo.description.length > 20) return $scope.alert('目錄標題描述限20個字');
      }

      if (item.subcatalogs.some(subcatalog => !subcatalog.name)) return $scope.alert('請填寫子目錄名稱');
      if (item.subcatalogs.some(subcatalog => {
        return !subcatalog.items.every(subcatalogItem => _.get(subcatalogItem, 'type'));
      })
      ) return $scope.alert('選單項目有空');

      return true;
    }

    function transform () {
      const item = $scope.currentActionItem;
      item.displayBelowBannerInfo = {
        isActivate: item.displayBelowBannerInfoIsActivate,
        image: _.get(item, '_displayBelowBannerInfoImage._id'),
        mobileImage: _.get(item, '_displayBelowBannerInfoMobileImage._id'),
        description: item.displayBelowBannerDescription
      };

      item.subcatalogs = item.subcatalogs.map(subcatalog => ({
        ...subcatalog,
        items: subcatalog.items.map(subcatalogItem => {
          if (subcatalogItem.type === 'serviceClassification') {
            return {
              type: subcatalogItem.type,
              serviceClassification: subcatalogItem.serviceClassification
            };
          } else if (subcatalogItem.type === 'link') {
            return {
              type: subcatalogItem.type,
              name: subcatalogItem.name,
              link: subcatalogItem.link,
              description: subcatalogItem.description,
              icon: _.get(subcatalogItem, '_icon._id', subcatalogItem.icon)
            };
          } else {
            return subcatalogItem;
          }
        })
      }));
      console.log('after transform: ', item);
    }

    async function syncCurrentActionItem () {
      const item = $scope.currentActionItem;
      const isEdit = $scope.currentPageState === 'edit';
      if (isEdit) {
        const catalog = await Catalog.one(item._id).customGET();

        item.displayBelowBannerInfoIsActivate = _.get(catalog, 'displayBelowBannerInfo.isActivate');
        item.displayBelowBannerInfoImage = _.get(catalog, 'displayBelowBannerInfo.image._id');
        item._displayBelowBannerInfoImage = _.get(catalog, 'displayBelowBannerInfo.image');
        item.displayBelowBannerInfoMobileImage = _.get(catalog, 'displayBelowBannerInfo.mobileImage._id');
        item._displayBelowBannerInfoMobileImage = _.get(catalog, 'displayBelowBannerInfo.mobileImage');
        item.displayBelowBannerDescription = _.get(catalog, 'displayBelowBannerInfo.description');

        item.subcatalogs = catalog.subcatalogs.map(subcatalog => ({
          ...subcatalog,
          items: subcatalog.items.map(subcatalogItem => {
            if (subcatalogItem.type === 'serviceClassification') {
              return {
                type: subcatalogItem.type,
                serviceClassification: _.get(subcatalogItem, 'serviceClassification._id'),
                name: _.get(subcatalogItem, 'serviceClassification.name')
              };
            } else if (subcatalogItem.type === 'link') {
              return {
                type: subcatalogItem.type,
                name: subcatalogItem.name,
                link: subcatalogItem.link,
                description: subcatalogItem.description,
                _icon: subcatalogItem.icon
              };
            } else {
              return subcatalogItem;
            }
          })
        }));
      }

      const classifications = await ServiceClassification.getList({ limit: 999, displayVisible: 'all' });
      $scope.classificationOptions = classifications.map(classification => ({
        label: classification.name,
        value: classification._id
      }));

      $scope.inputItems = getModuleAction(item, $scope);
    }

    const getModuleAction = (currentActionItem, $scope) => {
      $scope.imageObj = {
        label: '目錄代表圖*',
        subLabel: '電腦版：900px x 900px',
        type: 'single-file',
        index: '_displayBelowBannerInfoImage',
        originIndex: 'displayBelowBannerInfoImage',
        class: 'images-wrapper',
        success: $scope.singleFileUploaded,
        error: $scope.alert,
        withBorder: 'br-rl br-t',
        showIndex: false,
        validate: { width: { limit: 900 }, height: { limit: 900 } }
      };
      $scope.mobileImageObj = {
        label: '目錄代表圖*',
        subLabel: '手機版：900px x 600px',
        type: 'single-file',
        index: '_displayBelowBannerInfoMobileImage',
        originIndex: 'displayBelowBannerInfoMobileImage',
        class: 'images-wrapper',
        success: $scope.singleFileUploaded,
        error: $scope.alert,
        withBorder: 'br-rl',
        showIndex: false,
        validate: { width: { limit: 900 }, height: { limit: 600 } }
      };
      $scope.descriptionObj = { label: '目錄描述*', type: 'text', index: 'displayBelowBannerDescription', class: 'long', info: '最多20個字', withBorder: 'br-rl br-b', showIndex: false };

      function resetDisplayRestriction () {
        const displayBelowBannerInfoIsActivate = currentActionItem.displayBelowBannerInfoIsActivate;
        $scope.imageObj.showIndex = displayBelowBannerInfoIsActivate;
        $scope.mobileImageObj.showIndex = displayBelowBannerInfoIsActivate;
        $scope.descriptionObj.showIndex = displayBelowBannerInfoIsActivate;
      }
      resetDisplayRestriction();

      return [
        { label: '目錄標題名稱*', type: 'text', index: 'name', info: '最多6個字' },
        { label: '標題連結', type: 'text', index: 'link', class: 'long' },
        {
          label: '顯示於首頁Banner下方',
          type: 'radio',
          index: 'displayBelowBannerInfoIsActivate',
          radios: [
            { value: true, label: '是(需填寫下列)' },
            { value: false, label: '否' }
          ],
          changed: resetDisplayRestriction
        },
        $scope.imageObj,
        $scope.mobileImageObj,
        $scope.descriptionObj,
        {
          label: '服務選單顯示設定',
          type: 'radio',
          index: 'isVisible',
          radios: [
            { value: true, label: '顯示' },
            { value: false, label: '隱藏' }
          ],
          info: '控制該目錄是否在服務選單上顯示。'
        },
        {
          label: '廠商列表搜尋顯示設定',
          type: 'radio',
          index: 'isVenderListSearchVisible',
          radios: [
            { value: true, label: '顯示' },
            { value: false, label: '隱藏' }
          ],
          info: '如該目錄沒有要顯示在廠商列表服務篩選清單內，則請設定為隱藏。'
        },
        {
          label: 'TA3顯示設定',
          type: 'radio',
          index: 'isTa3Visible',
          radios: [
            { value: true, label: '顯示' },
            { value: false, label: '隱藏' }
          ],
          info: `<div>1.控制該服務分類被設定於服務選單-子目錄上時，該服務分類是否在TA3居家服務和廠商TA3資訊分頁服務項目顯示。</div>
              <div>2.如該服務為TA3居家服務廠商分類，則此項目請設定為顯示。</div>
              <div>3.TA3服務分類與項目選單每2分鐘(2、4、6...10)更新一次。</div>`
        },
        {
          label: '排序',
          type: 'number',
          index: 'sort',
          info: '數值越大越前面'
        },
        { type: 'underline' },
        { type: 'describe', describe: '下列資料可不設定；若無資料，預約服務選單將不會顯示內容' },
        { type: 'titleLabel', titleLabel: '新增子目錄' },
        {
          type: 'subcatalog',
          addRow: () => {
            currentActionItem.subcatalogs.push({
              name: '',
              sort: 0,
              items: []

            });
          },
          removeRow: (index) => {
            currentActionItem.subcatalogs.splice(index, 1);
          },
          addSubItem: (subcatalogs) => {
            subcatalogs.push({
              type: '',
              name: '',
              _icon: {},
              link: '',
              description: '',
              serviceClassification: ''
            });
          },
          removeSubItem: (parenIndex, index) => {
            currentActionItem.subcatalogs[parenIndex].items.splice(index, 1);
          },
          openItemSetting: async (parenIndex, index, subcatalog) => {
            currentActionItem.subcatalogs[parenIndex].items[index] = await subcatalogEditorDialog.openConfirm(subcatalog, $scope);
            $scope.$apply();
          },
          itemMoved: (parenIndex, index) => {
            currentActionItem.subcatalogs[parenIndex].items.splice(index, 1);
          }
        },
        { type: 'action' }
      ];
    };

    $scope.$watch('currentActionItem', syncCurrentActionItem);
  }];
