export default ['$scope', '$http', '$state', '$async', 'ywUtil', 'currentUser', 'address', 'ServiceClassification', 'confirmDialog', 'API_URL',
  function controller ($scope, $http, $state, $async, ywUtil, currentUser, address, ServiceClassification, confirmDialog, API_URL) {
    $scope.filterItems = [];
    $scope.tableHead = [];
    $scope.initActionItem = initActionItem;

    const countyOptions = address.getSortedCounties;
    const districtInput = {
      name: '_districts',
      label: '地區',
      options: [],
      changed: (districts) => {
        $scope.mainAction.queryParams.regions = address.getRegions(
          $scope.mainAction.queryParams._counties,
          districts
        );
        $scope.mainAction.setClearFilterTrue();
      },
      type: 'multiFilter',
      byCategory: true
    };
    const syncDistrictOptions = function (counties) {
      if (_.isEmpty(counties)) {
        $scope.mainAction.queryParams._districts = [];
        delete $scope.mainAction.queryParams.regions;
        districtInput.options = [];
      } else {
        districtInput.options = address.syncDistrictOptions(counties);
        $scope.mainAction.queryParams._districts =
          ($scope.mainAction.queryParams._districts || []).filter(
            district => counties.find(county => district.startsWith(county))
          );
        $scope.mainAction.queryParams.regions = address.getRegions(
          counties,
          $scope.mainAction.queryParams._districts
        );
      }
      $scope.mainAction.setClearFilterTrue();
    };

    $scope.filterItems = [
      {
        name: '_counties',
        label: '縣市',
        options: countyOptions,
        changed: syncDistrictOptions,
        type: 'multiFilter'
      },
      districtInput
    ];

    $scope.searchBarOptions = [
      {
        btnName: '分類名稱',
        placeholder: '請輸入分類名稱',
        params: 'classificationName'
      },
      {
        btnName: '服務方案',
        placeholder: '請輸入服務方案',
        params: 'serviceName'
      }
    ];
    $scope.searchBarOptionsActive = _.head($scope.searchBarOptions);

    $scope.changeSearchBarActiveOption = changeSearchBarActiveOption;

    function changeSearchBarActiveOption (currentOption, newOption) {
      $scope.searchBarOptionsActive = newOption;
      $scope.mainAction.queryParams[newOption.params] = $scope.mainAction.queryParams[currentOption.params];
      _.unset($scope.mainAction.queryParams, currentOption.params);
    }

    $scope.customTextSearchCb = _.debounce(function (text, page, name) {
      $scope.mainAction.queryParams[$scope.searchBarOptionsActive.params] = _.isEmpty(text)
        ? undefined
        : text;
      $scope.mainAction.filterQuery(text, page, name);
    }, 1000, {
      leading: true,
      trailing: false
    });
    $scope.customTextChangeCb = function (text) {
      $scope.mainAction.queryParams[$scope.searchBarOptionsActive.params] = _.isEmpty(text)
        ? undefined
        : text;
    };

    $scope.menuButtons = {
      edit: (item) => $scope.changePageState('edit', item),
      advancedFilterItems: (item) => {
        $state.go(
          'service-admin.service/service-classification/advanced-filter-item',
          { serviceClassificationId: item._id }
        );
      },
      advancedFilterName: $async(async (item) => {
        const value = await confirmDialog.openConfirm({
          title: '廠商進階篩選項目名稱',
          templateUrl: '/view/dialog/single-input.html',
          inputLabel: '設定名稱',
          tooltip: '無設定，則預設顯示"進階篩選"',
          confirmValue: item.advancedFilterTitle || ''
        });
        const data = _.clone(item);
        const imagePaths = ['entranceIcon', 'sampleImageFirst', 'sampleImageSecond', 'sampleImageThird', 'bookingFigureImage'];
        imagePaths.forEach(path => {
          if (_.get(item, `${path}._id`)) {
            data[path] = _.get(item, `${path}._id`);
          }
        });
        const result = await ServiceClassification.one(item._id).customPUT({ ...data, advancedFilterTitle: value });
        if (result.error) {
          $scope.alert(result.error);
        } else {
          item.advancedFilterTitle = value;
          $scope.success('設定完成');
        }
      })
    };

    const init = $async(async () => {
      // rendering options
      $scope.searchBar = ywUtil.getSearchbarSettings();
      $scope.toolbar = ywUtil.getToolbarSettings();
      $scope.tableHead = ywUtil.getTableHeader();

      $scope.queryPage = 'service-classification'; // for GA

      $scope.mainAction.initResource(ServiceClassification, { displayVisible: 'all' });

      const clearFilter = $scope.mainAction.clearFilter;
      $scope.mainAction.clearFilter = () => clearFilter(['displayVisible'], $scope.searchBar.textInput);
    });

    init();

    function initActionItem () {
      $scope.currentActionItem = {
        mode: 'general',
        recommendLinks: [],
        canCrossoverStandardService: false,
        canCrossoverService: false,
        reservationListMode: 'stateOrdered',
        _productLayout: 'publicVersion',
        _productPriceList: [],
        _productQAndA: [],
        _productMainFeature: [],
        _productIntroduction: [],
        isVisible: true,
        isVenderFilterListVisible: true,
        isTa3ServiceItemVisible: true,
        acquireCouponEvents: [],
        isDisplaySiteImages: true,
        isLimitedDealCustomerVerified: false,
        isCheckingCustomerBudget: true,
        isDisplayBookingInfo: true,
        _productServiceIntroductionTitle: '服務介紹'
      };
    }

    $scope.openAddressDetailDialog = (item) => {
      confirmDialog.openConfirm({
        title: '縣市/地區',
        templateUrl: '/view/dialog/address-detail.html',
        hideCancel: true,
        regions: item.regions
      });
    };

    $scope.delete = $async(async () => {
      const selectedItems = $scope.items.filter(item => item._selected);
      const selectedIds = selectedItems.map(item => item._id);
      if (!selectedItems.length) { return $scope.alert('請選擇要刪除的項目'); }

      await confirmDialog.openConfirm({ content: '確認刪除？' });
      const res = await $http({
        url: `${API_URL}serviceClassifications/batchRemove`,
        method: 'DELETE',
        headers: {
          authorization: `Bearer ${currentUser.getToken()}`,
          'Content-Type': 'application/json'
        },
        data: { classificationIds: selectedIds }
      });
      if (res.error) {
        $scope.alert(res.error);
      } else {
        for (let i = $scope.items.length - 1; i > -1; i--) {
          if (selectedIds.indexOf($scope.items[i]._id) > -1) $scope.items.splice(i, 1);
        }
        $scope.mainAction.pager.totalItems -= selectedIds.length;
        $scope.success('刪除完成');
      }
    });
  }];
